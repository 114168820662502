<template>
  <pt-bill-checked-reject class="pt-bill-checked-reject__withData"></pt-bill-checked-reject>
</template>

<script>
import PtBillCheckedReject from './index'
export default {
  name: 'pt-bill-checked-reject__withData',
  components: {
    'pt-bill-checked-reject': PtBillCheckedReject
  },
  props:{
    id:String,
  },
}
</script>
