<template>
  <div :class="$style['pt-bill-checked-reject']">
    <validate-form tag="div" ref="form">
      <pt-form-item
        class="form-item"
        tag="div"
        name="驳回原因"
        rules="required"
        ref="reason"
        :showError="true"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>驳回原因：</span>
          <wt-input v-model="formValue.reason" placeholder="请填写您的驳回原因">
          </wt-input>
          <!-- <cube-textarea v-model="formValue.reason" autofocus placeholder="请填写您的驳回原因"></cube-textarea> -->
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认提交</wt-button>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-bill-checked-reject'
import {nextTrust} from '../status'
export default {
  name: 'pt-bill-checked-reject',
  computed: {
    classes () {
      return `${prefixCls}`
    },
  },
  props:{
    id:String,
  },
  data () {
    return {
      formValue: {},
      // authorizationCode:null
    };
  },
  methods:{
    submit () {
      this.$refs.form.validate("formValue").then((validate) => {
        if(validate){
          let data = {
            billId:this.$route.params.id,
            action: 'BILL_CHECKED',
            billStatusDetail:{
              action: 'BILL_CHECKED',
              billStatusResult:'REJECT',
              reason: this.formValue.reason
            }
          }
          this.$loading()
          nextTrust.call(this,data).then(response => {
            this.$router.go(-1)
          }).catch(err => {
            this.$toast.error(err.message || err)
          }).finally(()=>{
            this.$loading.hide()
          })
        }
        
      });
      
    
    },
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-bill-checked-reject{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  :global{
    .wt-input{
      flex: 1;
    }
  }
}
</style>
