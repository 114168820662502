<template>
  <pt-bill-checked-reject class="pt-bill-checked-reject__page"></pt-bill-checked-reject>
</template>

<script>
import PtBillCheckedReject from 'cps/trust-order/pt-bill-checked-reject/withData'
export default {
  name: 'pt-bill-checked-reject__view',
  components: {
    'pt-bill-checked-reject': PtBillCheckedReject
  }
}
</script>
